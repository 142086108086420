<template>
  <v-footer
    dark
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2020, hecho en
            <v-icon>$custom</v-icon>
            por la carrera de <a href="https://ingenieria.mxl.uabc.mx/pe_ico/" style="color: green;">Ingeniería en Computación</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {  
    name: 'AdministradorCoreFooter',
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
